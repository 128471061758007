<template>

    <div class="card mb-3 shadow-sm  ribbon ribbon-clip ribbon-left">

      <div class="ribbon-target bg-warning pt-3" style="top: 10px;">
        <span class="h5 font-weight-bolder ">{{ this.ranking + 1 }}</span>
      </div>

      <div class="card-body align-items-center py-5 pl-12 pr-10">
        <div class="row">

          <div class="col-md-8 col-lg-8">
            <div class="d-flex" v-if="publishAssessmentsMaster && ideaSimpleAssessmentStatusId > 1">
              <div
                  class="flex-row px-2 py-1 float-left"
                  :style="'color:#fff; background-color:' + ideaSimpleAssessmentStatusColor"
              >
                <span>{{ assessment }}</span>
              </div>
            </div>
            <div class="flex-row my-lg-0 my-2 mb-4">
              <router-link
                :to="{
                  name: 'processModuleIdeaDetail',
                  params: {
                    id: idea.id,
                    process_id: process_id,
                    phase_id: phase_id,
                    module_id: module_id
                  }
                }"
                class="text-dark"
              >
                <h1 class="font-weight-bold font-size-h4 mt-2 mb-2">
                {{ idea.title | truncate(50, '...') }}</h1>
                <p class="mt-2 h6 font-weight-normal" v-if="idea.description">{{ idea.description | truncate(100, '...') }}</p>
              </router-link>

            </div>
          </div>

          <div class="col-lg-4">
            <div class="row justify-content-end align-items-center">
              <div class="d-flex flex-column text-right border-right px-5 mr-5"
                   v-if="(this.card_type == 'preferenze' || (mvp && this.card_type == 'classifica-finale'))"
              >
                <span class="text-dark-75 font-weight-bolder font-size-h3">{{idea.valueVotes1}}</span>
                <span class="text-muted font-weight-bold mt-1">{{$t("IDEARESULT.PREFERENZE")}}</span>
              </div>
              <div class="d-flex flex-column text-right border-right px-5 mr-5"
                   v-if="(this.card_type == 'budget-raccolto' || (mvb && this.card_type == 'classifica-finale'))"
              >
                <span class="text-dark-75 font-weight-bolder font-size-h3">{{idea.budgetMin}} €</span>
                <span class="text-muted font-weight-bold mt-1">{{$t("IDEARESULT.COSTOIDEA")}}</span>
              </div>
              <div class="d-flex flex-column text-right border-right px-5 mr-5"
                   v-if="(this.card_type == 'budget-raccolto' || (mvb && this.card_type == 'classifica-finale'))"
              >
                <span class="text-dark-75 font-weight-bolder font-size-h3">{{idea.valueVotes4}} €</span>
                <span class="text-muted font-weight-bold mt-1">{{$t("IDEARESULT.FINANZIATO")}}</span>
              </div>
              <div class="d-flex flex-column text-right border-right px-5 mr-5"
                   v-if="(this.card_type == 'budget-raccolto')"
              >
                <span class="text-dark-75 font-weight-bolder font-size-h3"> {{idea.countSupporters}}</span>
                <span class="text-muted font-weight-bold mt-1">{{$t("IDEARESULT.DONATORI")}}</span>
              </div>

              <div class="d-flex flex-column justify-content-end">
                <router-link
                    class="btn btn-sm btn-default btn-text-primary btn-hover-white btn-icon mr-2 mt-2"
                    :to="{
                    name: 'processModuleIdeaDetail',
                    params: {
                      id: idea.id,
                      process_id: process_id,
                      phase_id: phase_id,
                      module_id: module_id
                    }
                  }">
                  <span class="svg-icon svg-icon-primary svg-icon-2x">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    >
                    <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <rect
                          fill="#000000"
                          opacity="0.3"
                          transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000) "
                          x="7.5"
                          y="7.5"
                          width="2"
                          height="9"
                          rx="1"
                      />
                      <path
                          d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045
                            C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093
                            C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036
                            22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443
                            20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) "
                      />
                    </g>
                    </svg>
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "IdeaResultCard",
  props: ["idea", "card_type", "mvp", "mvb", "process_id","phase_id", "module_id", "ranking", "module" ],
  data() {
    return {
      publishAssessmentsMaster: false,
      assessment: '',
      ideaSimpleAssessmentStatusId: 1,
      ideaSimpleAssessmentStatusColor: ''
    }
  },
  created() {

    if(this.idea && this.module) {

      this.publishAssessmentsMaster = this.module.publishAssessmentsMaster || (this.idea && this.idea.assessor && this.idea.assessor.id && this.idea.assessor.id == this.currentUser.id);

      this.assessment = this.idea.ideaSimpleAssessmentStatus.localizations[0].name ?? '';
      this.ideaSimpleAssessmentStatusId = this.idea.ideaSimpleAssessmentStatus.id ?? 1;
      this.ideaSimpleAssessmentStatusColor = this.idea.ideaSimpleAssessmentStatus.color ?? '';
    }
  },
  computed: {
    ...mapGetters([
      "currentUser"
    ])
  },
  filters:{
    truncate(text, length, clamp){
      if (!text) return '';
      clamp = clamp || '...';
      let node = document.createElement('div');
      node.innerHTML = text;
      let content = node.textContent;
      return content.length > length ? content.slice(0, length) + clamp : content;
    },
  },
};
</script>

<style scoped></style>
