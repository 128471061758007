<template>

  <div class="row h-100 justify-content-between">
    <!-- col-sx -->
    <div class="d-none d-lg-block col-lg-2 col-xl-2 bg-white">
      <div class="pl-4">
        <Phaseline
            :phases="process.processPhases"
            :processId="process.id"
            :processLogo="getProcessLogo">
        </Phaseline>
      </div>
    </div>

    <!-- col center -->
    <div class="col-md-12 col-lg-9 col-xl-9">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-column mt-5 mx-6">
            <div class="card mb-3 card-stretch shadow-sm ribbon ribbon-clip ribbon-right">
              <div class="ribbon-target p-3" style="top: 12px;" v-if="phase && phase.phaseStatus">
                <span
                    class="ribbon-inner"
                    v-bind:class="{
                      'bg-primary': phase.phaseStatus.id == 2,
                      'bg-warning': phase.phaseStatus.id == 1,
                      'bg-danger': phase.phaseStatus.id == 3
                    }"
                ></span><span class=" text-uppercase font-weight-bolder">{{ phase.phaseStatus.name }}</span>
              </div>
              <div class="card-header border-0 pb-4">
                <div class="row w-100">
                  <!-- <div class="col-3 col-lg-2 col-xl-1">
                      <img
                      :src="getProcessLogo"
                      class="w-100 mb-3"
                    />
                  </div> -->
                  <div class="col-12">
                    <div
                        v-if="process.id"
                    >
                      <h2
                          class="text-dark text-hover-primary font-weight-bolder mb-3"
                      >
                        {{ process.localizations[0].title }}
                      </h2>
                    </div>
                    <div class="d-flex justify-content-start mb-3">
                      <div class="d-flex">
                        <span v-if="phase.openDate"> {{$t('GENERALLABEL.DAL')  | capitalize}} <b class="font-weight-bolder h5">{{ phase.openDate | moment("DD/MM/Y")}}</b> </span>
                        <span class="ml-4" v-if="phase.closeDate"> {{$t('GENERALLABEL.AL')}} <b  class="font-weight-bolder h5">{{ phase.closeDate | moment("DD/MM/Y")}}</b></span>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mb-3">
                      <div class="d-flex flex-row align-items-start">
                        <router-link
                            :to="{
                            name: 'processModuleIdeaList',
                            params: { id: module.id, process_id: module.process.id, phase_id: phase.id}
                          }"
                            v-bind:id="module.title"
                        >
                          <h3 class="flex-wrap flex-column text-dark text-hover-primary font-size-h3 font-weight-bolder mb-1">
                            <b>{{$t("IDEARESULT.FASE")}}</b> {{ phase.title }} | {{$t("IDEARESULT.RISULTATIVOTAZIONE")}}
                          </h3>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row gutter-b px-6">

        <b-tabs class="w-100" @click="orderByPreference">
          <b-tab active>
            <template v-slot:title>
              <div class="mx-2 p-3 h5 border-bottom border-4 border-bottom-primary">{{$t("IDEARESULT.CLASSIFICAFINALE")}}</div>
            </template>
            <b-card-text>
              <div
                  v-for="(item,index) in this.processIdeasCF"
                  :key="item.id"
                  class="col-lg-12">
                <IdeaResultCard
                    :idea="item"
                    :card_type="'classifica-finale'"
                    :mvp="moduleVote1IsPresent"
                    :mvb="moduleVote4IsPresent"
                    :process_id="process_id"
                    :phase_id="phase_id"
                    :module_id="id"
                    :ranking="index"
                    :module="module"
                    @incFollower="incrementFollower($event)"
                    @decFollower="decrementFollower($event)"
                ></IdeaResultCard>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab
              v-if="this.moduleVote1IsPresent && this.moduleVote4IsPresent"
              @click="orderByPreference" >
            <template v-slot:title>
            <h4 class="mx-2 p-3 border-bottom border-2 border-bottom-danger h5 text-danger">{{$t("IDEARESULT.FILTRAPERPREFERENZE")}}</h4>
            </template>
            <b-card-text>
            <div
                v-for="(item,index) in processIdeasOP"
                :key="item.id"
                class="col-lg-12">
              <IdeaResultCard
                  :idea="item"
                  :card_type="'preferenze'"
                  :mvp="moduleVote1IsPresent"
                  :mvb="moduleVote4IsPresent"
                  :process_id="process_id"
                  :phase_id="phase_id"
                  :module_id="id"
                  :ranking="index"
                  @incFollower="incrementFollower($event)"
                  @decFollower="decrementFollower($event)"
              ></IdeaResultCard>
            </div>
            </b-card-text>
          </b-tab>
          <b-tab
              v-if="this.moduleVote1IsPresent && this.moduleVote4IsPresent"
              @click="orderByBudget">
            <template v-slot:title>
            <h4 class="mx-2 p-3 border-bottom border-2 border-bottom-warning h5 text-warning">{{$t("IDEARESULT.FILTRAPERBUDGET")}}</h4>
            </template>
            <b-card-text>
            <div
                v-for="(item,index) in processIdeasOB"
                :key="item.id"
                class="col-lg-12">
              <IdeaResultCard
                  :idea="item"
                  :card_type="'budget-raccolto'"
                  :mvp="moduleVote1IsPresent"
                  :mvb="moduleVote4IsPresent"
                  :process_id="process_id"
                  :phase_id="phase_id"
                  :module_id="id"
                  :ranking="index"
                  @incFollower="incrementFollower($event)"
                  @decFollower="decrementFollower($event)"
              ></IdeaResultCard>
            </div>
            </b-card-text>
          </b-tab>

        </b-tabs>

      </div>
    </div>

    <!-- col dx -->
    <div class="d-none d-lg-block col-lg-1 col-xl-1 justify-content-between align-items-center">
      <div class="d-flex flex-column mt-5 mr-5">
        <router-link v-slot="{ href, navigate }"
          :to="{
          name: ''
        }">
          <a :href="href" class="mb-4 btn btn-icon bg-primary mx-auto d-flex btn-lg px-2"
             @click="navigate" id="pop-info">
            <span class="symbol symbol-rounded">
                <span class="svg-icon svg-icon-white svg-icon-2x">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"/>
                      <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                      <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1"/>
                      <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1"/>
                  </g>
              </svg></span>
            </span>
          </a>
        </router-link>
        <b-popover target="pop-info" triggers="hover focus">
          <template v-slot:title>{{$t("IDEARESULT.COMEFUNZIONA")}}</template>
          {{$t("IDEARESULT.TUTTOSUPROCESSO")}}
        </b-popover>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Phaseline from "@/view/layout/common/Phaseline";
import { mapGetters } from "vuex";
import { SET_CURRENT_PROCESS } from "@/core/services/store/processes.module";
import IdeaResultCard from "@/view/pages/modules/ideas/partials/IdeaResultCard";
import {processMixin} from "@/mixins/process";
import {ideaMixin} from "@/mixins/idea";

export default {
  name: "IdeaResult",
  mixins: [processMixin, ideaMixin],
  components: {
    Phaseline,
    IdeaResultCard,
  },
  data() {
    return {
      module: [],
      process: [],
      phase: [],
      id: this.$route.params.id,
      process_id: this.$route.params.process_id,
      phase_id: this.$route.params.phase_id,
      moduleVote1IsPresent: false,
      moduleVote4IsPresent: false,
      processIdeas: [],
      processIdeasCF: [],
      processIdeasOP: [],
      processIdeasOB: [],
      percentage: 0,
      // items: ["uno", "due", "tre"]
    };
  },
  computed: {
    ...mapGetters([
      "currentUser"
    ])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("IDEARESULT.ELENCOPROCESSI"), route: "/process-list" },
      { title: this.$t("IDEARESULT.PROCESSO"), route: "/process-detail/" + this.process_id },
      { title: this.$t("IDEARESULT.RISULTATI") }
    ]);
  },

  async created() {
    this.process = await this.getProcess(this.process_id)    
    this.$store.dispatch(SET_CURRENT_PROCESS, this.process);
    this.percentage = this.getPercentage(this.process);
    this.phase = this.getCurrentPhase(this.process, this.phase_id);    
    this.module = await this.getCurrentModule(this.process, this.id);    

    if(this.module) {
      const publishVotes = this.module.publishVotes || false;

      if(!publishVotes) {        
        this.$router.push({
          name: "processModuleIdeaList",
          params: {
            id: this.id,
            process_id: this.process_id,
            phase_id: this.phase_id,
          },
        });
      }

      this.module.processVotes.forEach( (item) => {
        if(item.voteTypes[0].id == 1) {
          this.moduleVote1IsPresent = true;          
        }
        if(item.voteTypes[0].id == 4) {
          this.moduleVote4IsPresent = true;          
        }
      })

      this.getIdeas();
    }
  },
  methods: {
    orderByPreference() {
      this.processIdeasOP = this.processIdeas
          .sort((a,b) => (a.valueVotes1 > b.valueVotes1) ? -1 : 1);
    },
    orderByBudget() {
      this.processIdeasOB = this.processIdeas
          .sort((a,b) => (a.valueVotes4 > b.valueVotes4) ? -1 : 1);
    },
    getIdeas() {
      this.$isLoading(true);
      return new Promise((resolve, reject) => {

        //Visualizza solo le idee pubbliche
        let qs = "&ideaStatusId.equals=3";

        //Visualizza solo la versione pubblicata
        qs += "&versionCurrent.equals=true";

        var endpoint = this.generateUrl('ideas', true);
        //generateUrl is done
        ApiService.get(this.$apiResourceOpen, endpoint + "?fks_=auth,isast&cnt_=supp&val_=vote1,vote4&process2ModuleId.equals=" + this.id + "&sort=valueVotes1" + qs)
          .then(res => {            
            this.processIdeas = res.data;

            this.processIdeasCF = this.processIdeas
                .sort((a,b) => (a.valueVotes1 > b.valueVotes1) ? -1 : 1);

            // this.processIdeas1Approved = this.processIdeas
            //     .filter(i => i.simpleAssessmentStatusApproved === true)
            //     .sort((a,b) => (a.valueVotes1 > b.valueVotes1) ? -1 : 1);
            //
            //
            // this.processIdeas1Rejected = this.processIdeas
            //     .filter(i => i.simpleAssessmentStatusApproved === false)
            //     .sort((a,b) => (a.valueVotes1 > b.valueVotes1) ? -1 : 1);
            //
            // this.processIdeas1NotEvaluated = this.processIdeas
            //     .filter(i => i.simpleAssessmentStatusApproved === null)
            //     .sort((a,b) => (a.valueVotes1 > b.valueVotes1) ? -1 : 1);
            //
            //
            // this.processIdeas2Approved = this.processIdeas.sort((a,b) => (a.valueVotes4 > b.valueVotes4) ? -1 : 1)
            //     .filter(i => i.simpleAssessmentStatusApproved === true);
            //
            // this.processIdeas2Rejected = this.processIdeas.sort((a,b) => (a.valueVotes4 > b.valueVotes4) ? -1 : 1)
            //     .filter(i => i.simpleAssessmentStatusApproved === false);
            //
            // this.processIdeas2NotEvaluated = this.processIdeas.sort((a,b) => (a.valueVotes4 > b.valueVotes4) ? -1 : 1)
            //     .filter(i => i.simpleAssessmentStatusApproved === null)
            //
            
           this.$isLoading(false);

          })
          .catch(() => {
            // .catch(({ response }) => {           
            // context.commit(SET_ERROR, [response.data.error]);
           this.$isLoading(false);
            reject(0);
          });
      });
    }

  }
};
</script>

<style scoped></style>
